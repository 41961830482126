// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import ability from "../configs/acl/ability";
import { getAbilities, getAdminAbilities } from "../utility/Utils";

const config = useJwt.jwtConfig;

const initialUser = () => {
    const item = window.localStorage.getItem("userData");
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
    name: "authentication",
    initialState: {
        userData: initialUser(),
    },
    reducers: {
        handleLogin: (state, action) => {
            const abilities = [];
            const roles = action.payload.roles;
            if (roles)
                roles.forEach((role) => {
                    abilities.push(
                        ...getAbilities(...role.permissions.split(";"))
                    );
                });
            if (action.payload.accountType === "SUPER_ADMIN")
                abilities.push(
                    ...[
                        {
                            action: "manage",
                            subject: "center",
                        },
                    ]
                );
            if (action.payload.accountType === "ADMIN")
                abilities.push(
                    ...[
                        {
                            action: "manage",
                            subject: "all",
                        },
                        ...getAbilities(...getAdminAbilities()),
                    ]
                );
            if (action.payload.accountType === "TEACHER")
                abilities.push(
                    ...[
                        {
                            action: "read",
                            subject: "teacher-schedule",
                        },
                        {
                            action: "manage",
                            subject: "teacher-tickets",
                        },
                    ]
                );
            if (action.payload.accountType === "STUDENT")
                abilities.push(
                    ...[
                        {
                            action: "read",
                            subject: "student-schedule",
                        },
                    ]
                );
            state.userData = {
                ...action.payload,
                ...{
                    role: action.payload.accountType,
                    ability: [
                        { action: "read", subject: "dashboard" },
                        ...abilities,
                    ],
                },
            };
            ability.update([
                { action: "read", subject: "dashboard" },
                ...abilities,
            ]);
            state[config.storageTokenKeyName] =
                action.payload[config.storageTokenKeyName];
            localStorage.setItem("userData", JSON.stringify(state.userData));
            localStorage.setItem(
                config.storageTokenKeyName,
                action.payload.accessToken
            );
        },
        handleLogout: (state) => {
            state.userData = {};
            state[config.storageTokenKeyName] = null;
            // ** Remove user, accessToken & refreshToken from localStorage
            localStorage.removeItem("userData");
            localStorage.removeItem(config.storageTokenKeyName);
        },
    },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
