import {
    Document,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import { maxBy } from "lodash";
import { toast } from "react-toastify";
import logo from "../assets/images/logo/logo.png";
import CustomTablePDF from "./PDFTable";
import * as XLSX from "xlsx";
import hslToHex from "hsl-to-hex";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
    num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
    const today = new Date();
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
    value,
    formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
    if (!value) return value;
    return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};
export function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value);
    let formatting = { month: "short", day: "numeric" };

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: "numeric", minute: "numeric" };
    }

    return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
    if (userRole === "admin") return "/";
    if (userRole === "client") return "/";
    return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: "#7367f01a", // for option hover bg-color
        primary: "#7367f0", // for selected option bg-color
        neutral10: "#7367f0", // for tags bg-color
        neutral20: "#ededed", // for input border-color
        neutral30: "#ededed", // for input hover border-color
    },
});
export const Cycles = {
    PRIMARY: "Primaire",
    SECONDARY: "Secondaire",
    BASE: "Base",
};

export const GroupTypes = {
    STANDARD: "Standard",
    EXAMS: "Semaine bloquée",
    REMEDIATION: "Remédiation",
};

export const ScheduleTypes = {
    STANDARD: "Standard",
    RAMADAN: "Ramadan",
    HOLIDAY: "Vacance",
    EXAMS: "Semaine bloquée",
    REMEDIATION: "Remédiation",
};

export const Reasons = [
    "Problème financier",
    "Pas de progression",
    "Conflit avec un enseignant",
    "Conflit avec l'administration",
    "Décision d'archipel : problème de comportement",
    "Décision d'archipel : difficulté d'apprentissage",
    "Niveau non inclus",
    "Problème emploi du temps",
    "Rythme de la classe faible",
    "Rythme de la classe très avancé",
    "Autre",
];
export const TeacherReasons = [
    "Problème financier",
    "Conflit avec l'administration",
    "Maladie",
    "Conflit avec un élève/enseignant",
    "Autre",
];
export const yearMonths = [
    { value: 1, label: "Janvier", days: 31 },
    { value: 2, label: "Février", days: 28 },
    { value: 3, label: "Mars", days: 31 },
    { value: 4, label: "Avril", days: 30 },
    { value: 5, label: "Mai", days: 31 },
    { value: 6, label: "Juin", days: 30 },
    { value: 7, label: "Juillet", days: 31 },
    { value: 8, label: "Août", days: 31 },
    { value: 9, label: "Septembre", days: 30 },
    { value: 10, label: "Octobre", days: 31 },
    { value: 11, label: "Novembre", days: 30 },
    { value: 12, label: "Décembre", days: 31 },
];
export const PERMISSIONS_LIST = [
    { name: "Gestion d'élèves", code: 10000, resource: "student" }, // Lecture : 1, Creation : 10 , Modification : 100, Suppression : 1000,
    { name: "Gestion des parents", code: 160000, resource: "parent" }, // Lecture : 1, Creation : 10 , Modification : 100, Suppression : 1000,
    { name: "Gestion des enseignants", code: 20000, resource: "teacher" },
    { name: "Gestion du staff", code: 30000, resource: "staff" },
    {
        name: "Gestion des emplois",
        code: 40000,
        resource: "schedule",
        read: true,
    },
    { name: "Gestion des niveaux", code: 50000, resource: "level", read: true },
    {
        name: "Gestion des sections",
        code: 60000,
        resource: "section",
        read: true,
    },
    { name: "Gestion des groupes", code: 70000, resource: "group", read: true },
    {
        name: "Gestion des matières",
        code: 80000,
        resource: "subject",
        read: true,
    },
    { name: "Gestion des salles", code: 90000, resource: "room", read: true },
    {
        name: "Gestion des établissements",
        code: 180000,
        resource: "establishment",
        read: true,
    },
    { name: "Gestion des roles & permissions", code: 100000, resource: "role" },
    {
        name: "Module assiduité",
        code: 110000,
        resource: "assiduity",
        read: true,
    },
    {
        name: "Module pédagogique ",
        code: 120000,
        resource: "peda",
        read: true,
    },
    {
        name: "Dashboard comptabilité",
        code: 130000,
        resource: "comptability-dashboard",
        fields: { read: true },
    },
    { name: "Gestion des charges", code: 140000, resource: "charge" },
    {
        name: "Gestion des paiements",
        code: 150000,
        resource: "payment",
        fields: { read: true, update: true },
    },
    {
        name: "Module SMS ",
        code: 170000,
        resource: "sms",
        fields: { manage: true },
    },
];
export const getAbilities = (...codes) => {
    if (!codes) return [];
    if (codes.length === 0) return [];
    const abilities = [];
    codes.forEach((code) => {
        const arrayOfDigits = Array.from(String(code), Number);
        const abilitiesFromCode = arrayOfDigits.slice(-4);
        const resourceCode = arrayOfDigits
            .slice(0, arrayOfDigits.length - 4)
            .concat([0, 0, 0, 0])
            .join("");
        // eslint-disable-next-line eqeqeq
        const permission = PERMISSIONS_LIST.find(
            (el) => el.code.toString() === resourceCode
        );
        if (permission) {
            if (abilitiesFromCode[0] === 1)
                abilities.push({
                    subject: permission.resource,
                    action: "read",
                });
            if (abilitiesFromCode[1] === 1)
                abilities.push({
                    subject: permission.resource,
                    action: "create",
                });
            if (abilitiesFromCode[2] === 1)
                abilities.push({
                    subject: permission.resource,
                    action: "edit",
                });
            if (abilitiesFromCode[3] === 1)
                abilities.push({
                    subject: permission.resource,
                    action: "delete",
                });
            if (
                abilitiesFromCode[0] === 1 &&
                abilitiesFromCode[1] === 1 &&
                abilitiesFromCode[2] === 1 &&
                abilitiesFromCode[3] === 1
            ) {
                abilities.push({
                    subject: permission.resource,
                    action: "manage",
                });
            }
        }
    });
    return abilities;
};
export const permissionToArray = (permissions) => {
    if (!permissions) return [];
    const arr = [];
    permissions.forEach((permission) => {
        if (permission.length < 4) {
            return;
        }
        const arrayOfDigits = Array.from(String(permission), Number);
        const abilitiesFromCode = arrayOfDigits.slice(-4);
        const resourceCode = arrayOfDigits
            .slice(0, arrayOfDigits.length - 4)
            .concat([0, 0, 0, 0])
            .join("");
        // eslint-disable-next-line eqeqeq
        const perm = PERMISSIONS_LIST.find((el) => el.code == resourceCode);
        if (!perm) {
            return;
        }
        if (abilitiesFromCode.filter((el) => el === 0 || el === 1).length !== 4)
            return;
        arr.push({
            [perm.code]: [
                abilitiesFromCode[0] === 1,
                abilitiesFromCode[1] === 1,
                abilitiesFromCode[2] === 1,
                abilitiesFromCode[3] === 1,
            ],
        });
    });
    return arr.reduce((prev, curr) => {
        return { ...prev, ...curr };
    }, {});
};

export const parsePermission = ({ code, abilities }) => {
    let result = +code;
    if (abilities[0]) result += 1000;
    if (abilities[1]) result += 100;
    if (abilities[2]) result += 10;
    if (abilities[3]) result += 1;
    return result;
};
export const getAdminAbilities = () => {
    return PERMISSIONS_LIST.map((el) => {
        const code = +el.code;
        return code + 1111;
    });
};
export const accountTypes = {
    ADMIN: "Admin",
    STUDENT: "Élève",
    STAFF: "Staff",
    TEACHER: "Enseignant",
    PARENT: "Parent",
};

//TODO: Edit error handler
export const errorHandler = (error, history) => {
    if (!error.response) toast.error("Erreur de connexion !");
    else if (error.response.status === 500) toast.error("Erreur du serveur !");
    else if (error.response.status === 404)
        toast.error("La resource demandée n'existe pas!");
    else if (error.response.status === 401) {
        toast.error("Veuillez reconnectez s'il vous plaît !");
        localStorage.clear();
        history?.push("/login");
    } else if (error.response.status === 403)
        toast.error("Vous n'avez pas la permission d'accèder à cette resource");
    else if (error.response.status === 400) {
        if (error.response.data?.errors?.length > 1)
            toast.error(
                <>
                    <ul>
                        {error.response.data.errors.map((error) => (
                            <li>{error.message}</li>
                        ))}
                    </ul>
                </>
            );
        else
            toast.error(
                error.response.data?.errors?.[0].message ??
                    "Quelque chose s'est mal passée"
            );
    } else toast.error("Quelque chose s'est mal passée");
};

export const flattenObj = (ob) => {
    const result = {};
    for (const i in ob) {
        if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {
                // Store temp in result
                result[`${i}.${j}`] = temp[j];
            }
        } else if (Array.isArray(ob[i])) {
            if (i === "children") {
                ob[i].forEach((child, index) => {
                    Object.entries(child)
                        .filter((el) => el[0] !== "id")
                        .forEach((curr) => {
                            if (
                                [
                                    "firstName",
                                    "lastName",
                                    "inscriptionDate",
                                ].includes(curr[0])
                            )
                                result[
                                    `Child ${index + 1}.${curr[0]}`
                                ] = `${curr[1]}`;
                        });
                });
            } else if (
                i === "subjects" ||
                i === "groups" ||
                i === "levels" ||
                i === "sections"
            ) {
                ob[i].forEach((curr, index) => {
                    result[`${i.slice(0, -1)}.${index + 1}`] = `${
                        curr.designation ?? "-"
                    }`;
                });
            } else if (i === "roles") {
                ob[i].forEach((curr, index) => {
                    result[`role.${index + 1}`] = `${curr.name ?? "-"}`;
                });
            } else {
                result[i] = ob[i].join(" | ");
            }
            // eslint-disable-next-line brace-style
        }
        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }
    }
    return result;
};
export const convertArrayOfObjectsToCSV = (args) => {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data, url;

    // eslint-disable-next-line prefer-const
    data = args.data || null;
    // eslint-disable-next-line eqeqeq
    if (data == null || !data.length) {
        return null;
    }
    // eslint-disable-next-line prefer-const
    url = args.fileUrl ?? "";
    // eslint-disable-next-line prefer-const
    columnDelimiter = args.columnDelimiter || ";";
    // eslint-disable-next-line prefer-const
    lineDelimiter = args.lineDelimiter || "\n";

    const headers = args?.headers;
    // eslint-disable-next-line prefer-const
    const longestKey = maxBy(data, (el) => Object.keys(el));
    keys = args?.keys || Object.keys(longestKey);
    keys = keys.filter((key) => !key.includes("id"));
    result = "";
    result += headers
        ? headers
              .filter((header) => !header.includes("id"))
              .join(columnDelimiter)
        : keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
        ctr = 0;
        keys.forEach(function (key) {
            if (key.includes("id")) return;
            if (ctr > 0) result += columnDelimiter;
            if (
                [
                    "profilePicture",
                    "cinPhoto",
                    "gradebook",
                    "birthCertificate",
                    "jobContract",
                    "diplomaPhoto",
                ].some((el) => key.includes(el))
            )
                result += url + item[key] || "-";
            else result += item[key] || "-";
            ctr++;
        });
        result += lineDelimiter;
    });
    return result;
};

export const generatePDF = (fields, data, title, { footer, subTitle }) => {
    const styles = StyleSheet.create({
        page: {
            padding: 10,
        },
        logoContainer: {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            textAlign: "right",
        },
        logo: {
            width: 150,
        },
        contentContainer: {
            marginTop: "20px",
        },
        titleContainer: {
            marginTop: "20px",
            width: "100%",
            textAlign: "center",
        },
        title: {
            fontFamily: "Helvetica-Bold",
            fontSize: "20px",
            textTransform: "uppercase",
            fontWeight: "bold",
            color: "#734eaf",
        },
        subTitleContainer: {
            marginTop: "10px",
            width: "100%",
            textAlign: "center",
        },
        subTitle: {
            fontFamily: "Helvetica-Bold",
            fontSize: "15px",
            textTransform: "uppercase",
            fontWeight: "bold",
            color: "#734eaf",
        },
        footerContainer: {
            marginTop: "20px",
            width: "100%",
            textAlign: "right",
        },
        footer: {
            fontFamily: "Helvetica-Bold",
            fontSize: "16px",
            color: "#000000",
            marginRight: "20px",
        },
    });
    const PDF = () => {
        return (
            <Document>
                <Page size={"A4"} style={styles.page}>
                    <View style={styles.logoContainer}>
                        <Image src={logo} style={styles.logo}></Image>
                    </View>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>{title}</Text>
                    </View>
                    {!!subTitle && (
                        <View style={styles.subTitleContainer}>
                            <Text style={styles.subTitle}>{subTitle}</Text>
                        </View>
                    )}
                    <View style={styles.contentContainer}>
                        <CustomTablePDF data={data} fields={fields} />
                    </View>
                    {!!footer && (
                        <View style={styles.footerContainer}>
                            <Text style={styles.footer}>{footer}</Text>
                        </View>
                    )}
                </Page>
            </Document>
        );
    };
    return PDF;
};
export const convertCsvToExcelBuffer = (
    csvString,
    fileName,
    columnDelimiter
) => {
    const arrayOfArrayCsv = csvString.split("\n").map((row) => {
        return row.split(columnDelimiter ?? ";");
    });
    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    XLSX.utils.book_append_sheet(wb, newWs);
    const rawExcel = XLSX.writeFile(wb, fileName ?? "test.xlsx");
    return rawExcel;
};
export const establishmentTypes = {
    STATE: "Étatique",
    PRIVATE: "Privé",
};
export const establishmentCycles = {
    SCHOOL: "Ecole",
    COLLEGE: "Collége",
    HIGH_SCHOOL: "Lycée",
};
export const paymentTypes = {
    CASH: "Espèce",
    CHECK: "Chèque",
    BANK_TRANSFER: "Versement bancaire",
    POSTAL_PAYMENT: "Versement postale",
    BANK_CARD: "Carte bancaire",
};
export const ticketTypes = {
    BEHAVIORAL: "Comportemental",
    LEARNING_DIFFICULTY: "Apprentissage",
    ASSIDUITY: "Assiduité",
};
export const ticketState = {
    TO_BE_PROCESSED: ["à traiter", "danger"],
    IN_PROGRESS: ["en cours", "warning"],
    RESOLVED: ["traité", "success"],
};
export const ticketReasons = {
    BEHAVIORAL: {
        CLATTER: "Bavardage",
        BAD_BEHAVIOR_IN_GROUP: "Mauvais comportement en classe",
        BAD_BEHAVIOR_IN_CENTER: "Mauvais comportement au centre",
    },
    LEARNING_DIFFICULTY: {
        POOR_GRADE: "Mauvaise note obtenue",
        TEACHER_NOTICE: "Remarque de l'enseigniant",
        CLASS_PARTICIPATION: "Participation en classe",
        TECHNICAL_PROBLEM: "Probléme technique",
        LESSONS_DIFFERENCE:
            "Les cours au lycée et au centre ne sont pas les mémes",
        UNDERSTANDING_PROPBLEMS: "Elève ne comprends pas en classe",
    },
    ASSIDUITY: {
        HIGH_ABSENCE_RATE: "Taux d'absence élevé",
        ABSENCE_RATE_BY_SUBJECT: "Taux d'absence par matiére",
    },
};
export const ticketSolutions = {
    CATCH_UP_SESSION: "Séance de rattrapage",
    PARENT_TEACHER_MEETING: "Réunion Parent/Enseignant",
    STUDENT_COACHING: "Coaching èleve",
    PARENT_CONTACTING: "Contacter parent",
    OTHER: "Autre",
};

export const randomColors = (nbr) => {
    if (nbr === 0) {
        return [];
    }
    const colors = [];
    const step = 360 / nbr;
    const shift = Math.floor(Math.random() * step);
    for (let index = 0; index < nbr; index++) {
        const hue = shift + index * step;
        colors.push(hslToHex(hue, 100, 30));
    }
    return colors;
};

export const parseLevelCycle = (cycle) => {
    switch (cycle) {
        case "PRIMARY":
            return "Primaire";
        case "BASE":
            return "Base";
        case "SECONDARY":
            return "Secondaire";
        default:
            return "";
    }
};

export const handleFlatPickerDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    return new Date(`${year}-${month}-${day}`);
};

export const getSchoolYearRange = (day, month, year) => {
    const today = new Date();
    let firstYear = year ?? today.getFullYear();
    if (typeof firstYear === "string") {
        firstYear = parseInt(firstYear);
    }
    if (
        !year &&
        (today.getMonth() + 1 < month ||
            (today.getMonth() + 1 === month && today.getDate() < day))
    ) {
        firstYear--;
    }
    const result = {
        start: new Date(firstYear, month - 1, day),
        end: new Date(firstYear + 1, month - 1, day),
    };
    return result;
};
