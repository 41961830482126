function checkStrEmpty(str) {
    return !(str && str.length > 1 && str.split(" ").join("").length > 0)
}

import { Text, View, StyleSheet } from "@react-pdf/renderer"
import React from "react"

function CustomTablePDF(props) {
    const BORDER_COLOR = "#734eaf"
    const BORDER_STYLE = "solid"
    const styles = StyleSheet.create({
        headerBg: {
            backgroundColor: "#951B800f"
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderWidth: 1
            // borderRightWidth: 0,
            // borderBottomWidth: 0,
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },

        tableCellHeader: {
            margin: 2,
            fontSize: 12,
            fontWeight: "bold",
            color: BORDER_COLOR
            // fontFamily: "CustomRoboto",
        },
        tableCell: {
            margin: 2,
            fontSize: 10
            // fontFamily: "CustomRoboto",
        },
        textCenter: {
            textAlign: "center"
        }
    })
    const { fields = [], data = [] } = props
    const tableCol = {
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    }
    return (
        <View style={styles.table}>
            <View style={[styles.tableRow, styles.headerBg]}>
                {fields.map((_item, _idx) => (
                    <View
                        key={_idx}
                        style={[tableCol, { width: `${_item.width}%` }]}
                    >
                        <Text
                            style={[
                                styles.tableCellHeader,
                                { textAlign: "center" }
                            ]}
                        >
                            {_item.title}
                        </Text>
                    </View>
                ))}
            </View>

            {data.map(
                (item, idx) => item && (
                    <View key={idx} style={styles.tableRow}>
                        {fields.map((_item, _idx) => {
                            const val = item[_item.value] || ""
                            const value_alt =
                                (_item.value_alt &&
                                    item[_item.value_alt]) ||
                                ""

                            if (_item.custom) {
                                return (
                                    <View
                                        key={_idx}
                                        style={[
                                            tableCol,
                                            { width: `${_item.width}%` }
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                styles.tableCell,
                                                item.style ? item.style : {}
                                            ]}
                                        >
                                            {_item.component(item)}
                                        </Text>
                                    </View>
                                )
                            } else {
                                return (
                                    <View
                                        style={[
                                            styles.tableCol,
                                            { width: `${_item.width}%` }
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                styles.tableCell,
                                                item.style ? item.style : {}
                                            ]}
                                        >
                                            {checkStrEmpty(val) ? value_alt : val || "-"}
                                        </Text>
                                    </View>
                                )
                            }
                        })}
                    </View>
                )
            )}
        </View>
    )
}

export default CustomTablePDF